
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import slid from '../assets/images/slide-2.jpg'; 
import slidMobile from '../assets/images/slide-mobile.jpg'; 
 
import ServiceItems from '../components/ServiceItems';

function Home() {
  const location = useLocation();
    useEffect(() => {
      // Extract the hash from the URL
      const hash = location.hash;
  
      if (hash) {
        // Find the target element by its ID
        const targetElement = document.querySelector(hash);
  
        if (targetElement) {
          // Scroll to the element smoothly
          targetElement.scrollIntoView({ behavior: 'smooth' });
  
          // Optionally, focus on the element for accessibility
          targetElement.focus();
        }
      }
    }, [location]);

  return (
    <div>

<div className="slider">
      <img className="slide-img" src={slid} alt="construction" />
      <div className="wrapper">
        <div className="frame">
          <div className="slide-des">
            <h1>UNLEASHING DREAMS<br /> &nbsp;&nbsp; BEYOND BLUE PRINTS</h1>
          </div>
        </div>
      </div>      
    </div>

  <div className="slider-mobile">
      <img className="slide-mobile-img" src={slidMobile} alt="construction" />
      <div className="wrapper">
        <div className="frame">
          <div className="slide-mobile-des">
            <h1>UNLEASHING DREAMS<br /> &nbsp;&nbsp; BEYOND BLUE PRINTS</h1>
          </div>
        </div>
      </div>      
  </div>
          {/* <div class="img-fade"></div> */}
    {/* <Element name="about"> */}
      <div className="second-wrapper">
        <div id="about" className="about-des">
          <h2>WE BUILD A UNIQUE WORLD</h2>
          <p>Jafari Construction Group(JCG) is a trusted real estate development company with over 2 decades of experience building homes and communities in Toronto. With a rich legacy spanning more than four decades, our experience extends globally. Committed to quality and innovation, we bring a wealth of expertise to every endeavor.</p>
        </div>
      </div>
    {/* </Element> */}

    <ServiceItems />
   
    <div className="second-wrapper leadership">
      <div className="divider-leadership">
        <div className="text-des">
          <h2>OUR LEADERSHIP CHRONICLE</h2>
          <p>Jafari Construction Group(JCG) is a trusted real estate development company with over 2 decades of experience building homes and communities in Toronto. With a rich legacy spanning more than four decades, our experience extends globally. Committed to quality and innovation, we bring a wealth of expertise to every endeavor.</p>
        </div>
      </div>
    </div>
    <div className="leadership-img">
      <div className="img-construction">
        <img src="../assets/images/home-birds.png" alt="home construction" />
      </div>   
    </div>
    <div className="wrapper">
      <section className="blog">
        <h2>Blog</h2>
        <div className="blog-section">
          <div className="blog-items">
            <Link className="smooth-scroll" to="/blog#blog-4"><img src="./assets/images/blog-4.jpg" alt="article 4" /></Link>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>April 25, 2025</span>
            </div>
            <p>We are excited to announce that Jafari Construction Group (JCG) will [...]</p>
          </div>
          <div className="blog-items">
            <Link className="smooth-scroll" to="/blog#blog-5"><img src="./assets/images/blog-5.jpg" alt="article 5" /></Link>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>Mar 25, 2025</span>
            </div>
            <p>We are excited to share an update on our latest Forest Hill luxury project [...]</p>
          </div>
          <div className="blog-items">
            <Link className="smooth-scroll" to="/blog#blog-6"><img src="./assets/images/blog-6.jpg" alt="article 6" /></Link>
            <div className="blog-date">
              <img src="./assets/images/calendar-icon.png" alt="calendar" /> <span>Mar 24, 2025</span>
            </div>
            <p>Another bridge from the same family whose sibling was the side-on subject [...]</p>
          </div>
        </div>
      </section>
    </div>

    </div>
  );
}

export default Home;
