import React, { useEffect, useState } from 'react';

import {NavLink, Link} from "react-router-dom";
import logo from '../assets/images/jcg-logo-edited-old.png';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 60) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={isScrolled ? 'scrolled' : ''}>
          <div className="logo">
            <Link to="/"><img src={logo} alt="jafari construction" /></Link>
          </div>
          <div className="menu">
          
            <ul>
              {/* <li><Link to="about" smooth={true} duration={500}>ABOUT&nbsp;US</Link></li> */}
              <li><Link className="smooth-scroll" to="/#about">ABOUT&nbsp;US</Link></li>
              <li><NavLink className="smooth-scroll" to="/blog">BLOG</NavLink></li>
              <li><NavLink className="smooth-scroll" to="/projects">PROJECTS</NavLink></li>
              <li><Link className="smooth-scroll" to="/#contact">CONTACT&nbsp;US</Link></li>
            </ul>
 
          </div>
        </nav>
    );
};

export default Navbar;
