import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
 
import blog1 from '../assets/images/blog-thumb-1.jpg'; 

function Blog(){
    const location = useLocation();
  const backgroundImage = 'url(../assets/images/blog.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  
  useEffect(() => {
    // Extract the hash from the URL
    const hash = location.hash;

    if (hash) {
      // Find the target element by its ID
      const targetElement = document.querySelector(hash);

      if (targetElement) {
        // Scroll to the element smoothly
        targetElement.scrollIntoView({ behavior: 'smooth' });

        // Optionally, focus on the element for accessibility
        targetElement.focus();
      }
    }
  }, [location]);

  return (
 <div>

        <div className="blog-slider">
          <div className="third-wrapper">
              <div className="frame">
                  <div id="projectSlideImg" style={style}>
                      <div className="img-fade"></div>
                  </div>
                  <div className="wrapper">
                      <div className="blog-slide-des">
                          <h1>BLOG</h1>
                      </div>
                  </div>
            </div>
        </div>
        </div>
 
 

        <section className="blogs">
            <div className="divider-blogs">
                <div className="blogs-items" id="blog-1" tabIndex="-1">
                    <img src={blog1} alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Feb 21, 2024</span>
                        </div>
                        <h2>Successful Negotiations and Approval at Committee of Adjustment Meeting for Rosemary Project</h2>
                        <p>We had committee of adjustment meeting for our Rosemary project and received approval for all our requested variances. Prior to the meeting, We engaged in challenging negotiations with our neighbors to reach an agreement that satisfied everyone while achieving the best outcome.</p>
                    </div>                   
                </div>
                
                <div className="blogs-items" id="blog-2" tabIndex="-1">
                    <img src="assets/images/blog-thumb-2.jpg" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Jul 1, 2024</span>
                        </div>
                        <h2>Expanding Affordable Housing Options in Toronto: Acquiring and Developing Properties for Alleviating the Housing Shortage</h2>
                        <p>We acquired two properties with the intention of developing them as part of the City of Toronto's initiative to expand affordable housing options. Our goal was to contribute to the ongoing effort to alleviate the housing shortage in the city by creating accessible and affordable living spaces.</p>
                    </div>
                </div>
               
                <div className="blogs-items" id="blog-3" tabIndex="-1">
                    <img src="assets/images/blog-3.png" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>May 24, 2024</span>
                        </div>
                        <h2>Ontario commits $30 million for new Angus Glen South elementary school in Markham</h2>
                        <p>The Angus Glen South Elementary School project is part of a $1.3 billion investment to build new schools and expansions, including child care spaces, announced by Stephen Lecce, Minister of Education.</p>
                        <p>However, Joe Tigani, educational assistant and president of CUPE’s Ontario School Boards Council of Unions (OSBCU), criticized the government’s new funding model, saying it doesn’t address current staffing shortfalls and fails to invest in more front-line staff to improve the quality of education.</p>
                    </div>
                </div>

                <div className="blogs-items" id="blog-4" tabIndex="-1">
                    <img src="assets/images/blog-4.jpg" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>April 25, 2025</span>
                        </div>
                        <h2>Participating in the Living Luxe Design Show (LLDS) 2025, taking place from April 25-27</h2>
                        <p>We are excited to announce that Jafari Construction Group (JCG) will be participating in the Living Luxe Design Show (LLDS) 2025, taking place from April 25-27. As a leader in luxury real estate development in Toronto, we are proud to showcase our expertise, design philosophy, and innovative approach to high-end construction.</p>
                        <p>At our booth, we will be presenting our latest projects, highlighting our commitment to quality, craftsmanship, and cutting-edge design. This is a great opportunity to connect, explore new ideas, and discuss how we bring visionary concepts to life.</p>
                        <p>Join us at LLDS 2025 to meet our team, discover our work, and learn more about what sets JCG apart in the world of luxury real estate.</p>
                        <p>Event Details:<br/>
                        📍 &nbsp; Toronto Congress Centre<br/>
                        📅 April 25-27, 2025<br/>
                        📌 Booth #49<br/>
                        </p>
                        <p>Stay tuned for more updates, and we look forward to seeing you there!</p>
                    </div>
                </div>

                <div className="blogs-items" id="blog-5" tabIndex="-1">
                    <img src="assets/images/blog-5.jpg" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Mar 25, 2025</span>
                        </div>
                        <h2>JCG’s Forest Hill Project: Luxury in the Making</h2>
                        <p>
                        We are excited to share an update on our latest Forest Hill luxury project, which is currently at the rough-in stage and progressing on schedule for completion by the end of 2025.</p>
                        <p>This project exemplifies Jafari Construction Group’s commitment to exceptional craftsmanship, innovative design, and premium quality. Situated in one of Toronto’s most prestigious neighborhoods, this residence will reflect timeless elegance and modern sophistication, with every detail carefully considered to meet the highest standards.</p>
                        <p>As we move forward with the next phases of construction, we remain dedicated to delivering an unparalleled level of quality that defines JCG’s signature approach to luxury real estate. Stay tuned for more updates as we bring this extraordinary vision to life!</p>
                    </div>
                </div>

                <div className="blogs-items" id="blog-6" tabIndex="-1">
                    <img src="assets/images/blog-6.jpg" alt="blog"/>
                    <div className="blogs-des">
                        <div className="blogs-date">
                            <img src="assets/images/calendar-icon.png" alt="calendar"/> <span>Mar 24, 2025</span>
                        </div>
                        <h2>Sweeping Nightlines</h2>
                        <p>Another bridge from the same family whose sibling was the side-on subject of yesterday's Daily Photo, stars in today's, this time a head-on view of the Commissioner's Street Bridge over the new mouth of the Don River.</p>
                        <p>Captured by UrbanToronto, the double vaults of the Commissioners Street bridge provide bright orange arcs across the night sky of Toronto's Port Lands.</p>
                    </div>
                </div>
            </div>
        </section>
        
 
        <div className="blogs-leadership-img">
            <div className="img-construction">
                <img src="assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
 

    <div className="wrapper">
 
    </div>



  
    </div>
  );
}

export default Blog;