// import React, { useState } from 'react';
// import constructionImage from 'assets/images/construction.png'; // Assuming the image path correctly resolves

// const ServiceItem = () => {
//     const [showMore, setShowMore] = useState(false);

//     const readMore = () => {
//         setShowMore(prevShowMore => !prevShowMore);
//     };

//     return (
//         <div className="service-items">
//             <img src={constructionImage} alt="CONSTRUCTION" />
//             <h3>CONSTRUCTION</h3>
//             <p onMouseOver={readMore} style={{ cursor: 'pointer' }}>
//                 Construction JCG's construction division is a team dedicated to 
//                 <span style={{ display: showMore ? 'none' : 'inline' }} id="dots">...</span>
//                 <span style={{ display: showMore ? 'inline' : 'none' }} id="more">making dreams come true.</span>
//             </p>
//         </div>
//     );
// };

// export default ServiceItem;


import React, { useState } from 'react';

const ServiceItems = () => {
   
    const [showMoreConstruction, setShowMoreConstruction] = useState(false);
    const [showMoreFinancing, setShowMoreFinancing] = useState(false);
    const [showMoreDevelopment, setShowMoreDevelopment] = useState(false);
    const [showMoreConsultation, setShowMoreConsultation] = useState(false);

    const toggleConstruction = () => {
        setShowMoreConstruction(prev => !prev);
    };

    const toggleFinancing = () => {
        setShowMoreFinancing(prev => !prev);
    };

    const toggleDevelopment = () => {
        setShowMoreDevelopment(prev => !prev);
    };
    
    const toggleConsultation = () => {
        setShowMoreConsultation(prev => !prev);
    };

    const handleMouseOut = () => {
        setShowMoreConstruction(false);
        setShowMoreFinancing(false);
        setShowMoreDevelopment(false);
        setShowMoreConsultation(false);
      };

    return (
        <div>
            <div className="wrapper">
                <div className="services">
                    <div className="service-items">
                        <img src="assets/images/construction.png" alt="construction" />
                        <h3>CONSTRUCTION</h3>
                        <p onMouseOver={toggleConstruction} onMouseOut={handleMouseOut} style={{ cursor: 'pointer' }}>
                            Construction JCG's construction division is a team dedicated to
                            {showMoreConstruction ? null : <span id="dots">...</span>}
                            {showMoreConstruction && <span> making dreams come true.</span>}
                        </p>
                    </div>

                    <div className="service-items">
                        <img src="assets/images/financing.png" alt="financing" />
                        <h3>FINANCING</h3>
                        <p onMouseOver={toggleFinancing} onMouseOut={handleMouseOut} style={{ cursor: 'pointer' }}>
                            Financing Jafari Construction Group's financing division stands out for
                            {showMoreFinancing ? null : <span id="dots2">...</span>}
                            {showMoreFinancing && <span> its commitment to tailored financial solutions.</span>}
                        </p>
                    </div>

                    <div className="service-items">
                        <img src="assets/images/development.png" alt="development" />
                        <h3>DEVELOPMENT MANAGEMENT</h3>
                        <p onMouseOver={toggleDevelopment} onMouseOut={handleMouseOut} style={{ cursor: 'pointer' }}>
                            Development Management Jafari Construction Group's development
                            {showMoreDevelopment ? null : <span id="dots3">...</span>}
                            {showMoreDevelopment && <span> management division is the cornerstone of our commitment to excellence in construction projects.</span>}
                        </p>
                    </div>

                    <div className="service-items">
                        <img src="assets/images/consultation.png" alt="consultation" />
                        <h3>CONSULTATION</h3>
                        <p onMouseOver={toggleConsultation} onMouseOut={handleMouseOut} style={{ cursor: 'pointer' }}>
                            Consultation At JCG, our consultation division serves as a trusted
                            {showMoreConsultation ? null : <span id="dots4">...</span>}
                            {showMoreConsultation && <span> partner, offering expert guidance and strategic insights to navigate the complexities of the construction industry.</span>}
                        </p>
                    </div>   
                </div>
            </div>                     
        </div>
    );
};

export default ServiceItems;
