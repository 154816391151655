import React from "react";
import SlideShow from '../components/slideshow/ProjectTwoSlide' 

function ProjectOne(){
  const backgroundImage = 'url(../assets/images/hedgewood.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  return (
 <div>
 
    <div className="project-slider">
        <div className="third-wrapper">
            
            <div className="frame">
            <div id="projectSlideImg" style={style}>
                <div className="img-fade"></div>
                </div>
                <div className="wrapper">
                    <div className="project-slide-des">
                    <h1>York Mills Rd</h1>
                    <p>31 Hedgewood Rd, North York, M2L 1L6.</p>
                    </div>
                </div>
            </div>     
        </div> 
    </div>
 
  
        <section className="project">
            <div className="divider-project">
                <div className="project-des">
                  <h2>Project Details</h2>
                  <p><strong>Lot:</strong> 67' x 145'</p>
                  <p><strong>Total SQF:</strong> 5,600 Sqf</p>
                  <p><strong>Location:</strong> 31 Hedgewood Rd, North York, M2L 1L6.</p>
                  <p><strong>Status:</strong> Completed (Sep 2021 - Mar 2023)</p>
                  <p><strong>Architect:</strong> Urban Scape Architects</p>
                  <p><strong>Interior:</strong> Urban Scape Architects</p>
                </div>

            </div>
        </section>
        
 
        <div className="project-leadership-img">
            <div className="img-construction">
                <img src="../assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
        
        
    <div className="wrapper">
      <section className="project-des-section">

        <h3>Project Description</h3>
        <p>Description</p>

      </section>
    </div>


    <div className="wrapper">
        <section className="project-section">

                <SlideShow/>
          
        </section>
    </div>
 
  
    </div>
  );
}

export default ProjectOne;