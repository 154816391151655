import React, { useState, useEffect, useRef } from 'react';
import './Slideshow.css';

const AUTOPLAY_INTERVAL = 3000;

  const IMAGES = [
    '../assets/images/gallery/princess-01.jpg',
    '../assets/images/gallery/princess-02.jpg',
    '../assets/images/gallery/princess-03.jpg',
    '../assets/images/gallery/princess-04.jpg',
    '../assets/images/gallery/princess-05.jpg',
    '../assets/images/gallery/princess-06.jpg',
    '../assets/images/gallery/princess-07.jpg',
    '../assets/images/gallery/princess-08.jpg',
  ];


const SlideshowControls = ({ onPrev, onNext }) => (
  <>
    <button className="prev" onClick={onPrev}>&#10094;</button>
    <button className="next" onClick={onNext}>&#10095;</button>
  </>
);

const SlideshowDots = ({ count, current, onDotClick }) => (
  <div className="navigation-dots">
    {Array.from({ length: count }).map((_, index) => (
      <span
        key={index}
        className={`dot ${current === index ? 'active' : ''}`}
        onClick={() => onDotClick(index)}
      />
    ))}
  </div>
);

const LightboxModal = ({ isOpen, onClose, currentSlide, images, onPrev, onNext, imageRect }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => setIsZoomed(true), 50);
      return () => clearTimeout(timeout);
    }
    setIsZoomed(false);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="lightbox-modal" onClick={onClose}>
      <span className="close-modal">&times;</span>
      <button className="modal-prev" onClick={(e) => { e.stopPropagation(); onPrev(); }}>
        &#10094;
      </button>
      <button className="modal-next" onClick={(e) => { e.stopPropagation(); onNext(); }}>
        &#10095;
      </button>
      <img
        src={images[currentSlide]}
        alt={`Full size ${currentSlide + 1}`}
        className={`modal-image ${isZoomed ? 'zoomed' : ''}`}
        onClick={(e) => e.stopPropagation()}
        style={{
          left: `${imageRect?.left}px`,
          top: `${imageRect?.top}px`,
          width: `${imageRect?.width}px`,
          height: `${imageRect?.height}px`,
        }}
      />
    </div>
  );
};

const ProjectOneSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageRect, setImageRect] = useState(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!isModalOpen) {
      intervalRef.current = setInterval(() => moveSlide(1), AUTOPLAY_INTERVAL);
    }
    return () => clearInterval(intervalRef.current);
  }, [currentSlide, isModalOpen]);

  const moveSlide = (n) => {
    setCurrentSlide(prev => (prev + n + IMAGES.length) % IMAGES.length);
  };

  const openModal = (index, event) => {
    setImageRect(event.target.getBoundingClientRect());
    clearInterval(intervalRef.current);
    setCurrentSlide(index);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="slideshow-container">
        <div className="slide-wrapper">
          {IMAGES.map((img, index) => (
            <div
              key={index}
              className={`slide ${currentSlide === index ? 'active' : ''}`}
              style={{ backgroundImage: `url(${img})` }}
              onClick={(e) => openModal(index, e)}
            />
          ))}
        </div>

        <SlideshowControls onPrev={() => moveSlide(-1)} onNext={() => moveSlide(1)} />
        <SlideshowDots
          count={IMAGES.length}
          current={currentSlide}
          onDotClick={setCurrentSlide}
        />
      </div>

      <LightboxModal
        isOpen={isModalOpen}
        onClose={closeModal}
        currentSlide={currentSlide}
        images={IMAGES}
        onPrev={() => moveSlide(-1)}
        onNext={() => moveSlide(1)}
        imageRect={imageRect}
      />
    </>
  );
};


export default ProjectOneSlideshow;

