import React from "react";
import SlideShow from '../components/slideshow/ProjectOneSlide' 

function ProjectOne(){
  const backgroundImage = 'url(../assets/images/241-princess-slide.jpg)';

  const style = {
      backgroundImage,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
  };

  return (
 <div>
 
    <div className="project-slider">
        <div className="third-wrapper">
            
            <div className="frame">
            <div id="projectSlideImg" style={style}>
                <div className="img-fade"></div>
                </div>
                <div className="wrapper">
                    <div className="project-slide-des">
                      <h1>Yonge & Sheppard</h1>
                      <p>241 princess Ave, Northyork.</p>
                    </div>
                </div>
            </div>     
        </div> 
    </div>
 
  
        <section className="project">
            <div className="divider-project">
                <div className="project-des">
                    <h2>Project Details</h2>
                    <p><strong>Lot:</strong> 50' x 130'</p>
                    <p><strong>Total SQF:</strong> 4,600 Sqf</p>
                    <p><strong>Location:</strong> 241 princess Ave, North York.</p>
                    <p><strong>Status:</strong> Completed (Feb 2005 - Jan 2006)</p>
                    <p><strong>Architect:</strong> Bijan Jafari</p>
                    <p><strong>Interior:</strong> JCG Homes</p>
                </div>

            </div>
        </section>
        
 
        <div className="project-leadership-img">
            <div className="img-construction">
                <img src="../assets/images/home-birds.png" alt="home construction"/>
            </div>   
        </div>
 
    <div className="wrapper">
      <section className="project-des-section">

        <h3>Project Description</h3>
        <p>Description</p>

      </section>
    </div>

    <div className="wrapper">
        <section className="project-section">

                <SlideShow/>
          
        </section>
    </div>





  
    </div>
  );
}

export default ProjectOne;