import React, { useState, useEffect, useRef } from 'react';
import './Slideshow.css';

const AUTOPLAY_INTERVAL = 3000;
const IMAGES = [
    '../assets/images/gallery/hedgewood-rd-2.jpg',
    '../assets/images/gallery/hedgewood-rd-3.jpg',
    '../assets/images/gallery/hedgewood-rd-4.jpg',
    '../assets/images/gallery/hedgewood-rd-5.jpg',
    '../assets/images/gallery/hedgewood-rd-6.jpg',
    '../assets/images/gallery/hedgewood-rd-7.jpg',
    '../assets/images/gallery/hedgewood-rd-8.jpg',
    '../assets/images/gallery/hedgewood-rd-9.jpg',
    '../assets/images/gallery/hedgewood-rd-10.jpg',
    '../assets/images/gallery/hedgewood-rd-11.jpg',
    '../assets/images/gallery/hedgewood-rd-12.jpg',
];

const SlideshowControls = ({ onPrev, onNext }) => (
  <>
    <button className="prev" onClick={onPrev}>&#10094;</button>
    <button className="next" onClick={onNext}>&#10095;</button>
  </>
);

const SlideshowDots = ({ count, current, onDotClick }) => (
  <div className="navigation-dots">
    {Array.from({ length: count }).map((_, index) => (
      <span
        key={index}
        className={`dot ${current === index ? 'active' : ''}`}
        onClick={() => onDotClick(index)}
      />
    ))}
  </div>
);

const LightboxModal = ({ isOpen, onClose, currentSlide, images, onPrev, onNext, imageRect }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => setIsZoomed(true), 50);
      return () => clearTimeout(timeout);
    }
    setIsZoomed(false);
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="lightbox-modal" onClick={onClose}>
      <span className="close-modal">&times;</span>
      <button className="modal-prev" onClick={(e) => { e.stopPropagation(); onPrev(); }}>
        &#10094;
      </button>
      <button className="modal-next" onClick={(e) => { e.stopPropagation(); onNext(); }}>
        &#10095;
      </button>
      <img
        src={images[currentSlide]}
        alt={`Full size ${currentSlide + 1}`}
        className={`modal-image ${isZoomed ? 'zoomed' : ''}`}
        onClick={(e) => e.stopPropagation()}
        style={{
          left: `${imageRect?.left}px`,
          top: `${imageRect?.top}px`,
          width: `${imageRect?.width}px`,
          height: `${imageRect?.height}px`,
        }}
      />
    </div>
  );
};

const ProjectTwoSlideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageRect, setImageRect] = useState(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (!isModalOpen) {
      intervalRef.current = setInterval(() => moveSlide(1), AUTOPLAY_INTERVAL);
    }
    return () => clearInterval(intervalRef.current);
  }, [currentSlide, isModalOpen]);

  const moveSlide = (n) => {
    setCurrentSlide(prev => (prev + n + IMAGES.length) % IMAGES.length);
  };

  const openModal = (index, event) => {
    setImageRect(event.target.getBoundingClientRect());
    clearInterval(intervalRef.current);
    setCurrentSlide(index);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="slideshow-container">
        <div className="slide-wrapper">
          {IMAGES.map((img, index) => (
            <div
              key={index}
              className={`slide ${currentSlide === index ? 'active' : ''}`}
              style={{ backgroundImage: `url(${img})` }}
              onClick={(e) => openModal(index, e)}
            />
          ))}
        </div>

        <SlideshowControls onPrev={() => moveSlide(-1)} onNext={() => moveSlide(1)} />
        <SlideshowDots
          count={IMAGES.length}
          current={currentSlide}
          onDotClick={setCurrentSlide}
        />
      </div>

      <LightboxModal
        isOpen={isModalOpen}
        onClose={closeModal}
        currentSlide={currentSlide}
        images={IMAGES}
        onPrev={() => moveSlide(-1)}
        onNext={() => moveSlide(1)}
        imageRect={imageRect}
      />
    </>
  );
};

export default ProjectTwoSlideshow;



//////////////////////////////OK//////////////////////////////////
// import React, { useState, useEffect, useRef } from 'react';
// import './Slideshow.css';

// const AUTOPLAY_INTERVAL = 3000;
// const ProjectTwoSlideshow = () => {
//   const images = [
//     '../assets/images/gallery/hedgewood-rd-2.jpg',
//     '../assets/images/gallery/hedgewood-rd-3.jpg',
//     '../assets/images/gallery/hedgewood-rd-4.jpg',
//     '../assets/images/gallery/hedgewood-rd-5.jpg',
//     '../assets/images/gallery/hedgewood-rd-6.jpg',
//     '../assets/images/gallery/hedgewood-rd-7.jpg',
//     '../assets/images/gallery/hedgewood-rd-8.jpg',
//     '../assets/images/gallery/hedgewood-rd-9.jpg',
//     '../assets/images/gallery/hedgewood-rd-10.jpg',
//     '../assets/images/gallery/hedgewood-rd-11.jpg',
//     '../assets/images/gallery/hedgewood-rd-12.jpg',

//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const intervalRef = useRef(null);

//   useEffect(() => {
//     if (!isModalOpen) {
//       intervalRef.current = setInterval(() => {
//         moveSlide(1);
//       }, AUTOPLAY_INTERVAL);
//     }
    
//     return () => clearInterval(intervalRef.current);
//   }, [currentSlide, isModalOpen]);

//   const moveSlide = (n) => {
//     const newIndex = (currentSlide + n + images.length) % images.length;
//     setCurrentSlide(newIndex);
//   };

//   const openModal = (index) => {
//     clearInterval(intervalRef.current);
//     setCurrentSlide(index);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <div className="slideshow-container">
//         <div className="slide-wrapper">
//           {images.map((img, index) => (
//             <div
//               key={index}
//               className={`slide ${currentSlide === index ? 'active' : ''}`}
//               style={{ backgroundImage: `url(${img})` }}
//               onClick={() => openModal(index)}
//             />
//           ))}
//         </div>

//         <button className="prev" onClick={() => moveSlide(-1)}>
//         &#10094;
//         </button>
//         <button className="next" onClick={() => moveSlide(1)}>
//         &#10095;
//         </button>

//         <div className="navigation-dots">
//           {images.map((_, index) => (
//             <span
//               key={index}
//               className={`dot ${currentSlide === index ? 'active' : ''}`}
//               onClick={() => setCurrentSlide(index)}
//             />
//           ))}
//         </div>
//       </div>

//       {/* Lightbox Modal */}
//       {isModalOpen && (
//         <div className="lightbox-modal" onClick={closeModal}>
//           <span className="close-modal" onClick={closeModal}>
//             &times;
//           </span>
//           <button className="modal-prev" onClick={(e) => { e.stopPropagation(); moveSlide(-1); }}>
//           &#10094;
//           </button>
//           <button className="modal-next" onClick={(e) => { e.stopPropagation(); moveSlide(1); }}>
//           &#10095;
//           </button>
//           <img
//             src={images[currentSlide]}
//             alt={`Full size ${currentSlide + 1}`}
//             className="modal-image"
//             onClick={(e) => e.stopPropagation()}
//           />
//         </div>
//       )}
//     </>
//   );
// };

// export default ProjectTwoSlideshow;

///////////////////////////////////////////////////////////////////
// import React, { useState } from 'react';
// import './Slideshow.css'; 

// const ProjectTwoSlideshow = () => {
//   const images = [
//     '../assets/images/gallery/hedgewood-rd-2.jpg',
//     '../assets/images/gallery/hedgewood-rd-3.jpg',
//     '../assets/images/gallery/hedgewood-rd-4.jpg',
//     '../assets/images/gallery/hedgewood-rd-5.jpg',
//     '../assets/images/gallery/hedgewood-rd-6.jpg',
//     '../assets/images/gallery/hedgewood-rd-7.jpg',
//     '../assets/images/gallery/hedgewood-rd-8.jpg',
//     '../assets/images/gallery/hedgewood-rd-9.jpg',
//     '../assets/images/gallery/hedgewood-rd-10.jpg',
//     '../assets/images/gallery/hedgewood-rd-11.jpg',
//     '../assets/images/gallery/hedgewood-rd-12.jpg',

//   ];

//   const [currentSlide, setCurrentSlide] = useState(0);

//   const moveSlide = (n) => {
//     let newIndex = currentSlide + n;
//     if (newIndex < 0) {
//       newIndex = images.length - 1;
//     } else if (newIndex >= images.length) {
//       newIndex = 0;
//     }
//     setCurrentSlide(newIndex);
//   };

//   const currentSlideHandler = (index) => {
//     setCurrentSlide(index);
//   };

//   return (
//     <div className="slideshow-container">
//       {images.map((img, index) => (
//         <div
//           className={`slides ${currentSlide === index ? 'active' : ''}`}
//           key={index}
//         >
//           <img src={img} alt={`Slide ${index + 1}`} />
//         </div>
//       ))}
//       <a className="prev" onClick={() => moveSlide(-1)}>&#10094;</a>
//       <a className="next" onClick={() => moveSlide(1)}>&#10095;</a>
//       <div className="navigator">
//         {images.map((_, index) => (
//           <span
//             key={index}
//             className={`dot ${currentSlide === index ? 'active' : ''}`}
//             onClick={() => currentSlideHandler(index)}
//           ></span>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProjectTwoSlideshow;

